import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Stack,
  SvgIcon
} from '@mui/material';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';

import {
  ACCOUNT_SETTINGS_PATH,
  EXPLORE_API_PATH,
  HOME_PAGE_PATH,
  drawerResponsiveWidth
} from '../../../constants/general';
import { determineHref } from '../../../helpers/link.helper';
import { useCommonBreakpoints } from '../../../hooks/use-breakpoints.hooks';
import { useCommandPalette } from '../../../hooks/use-command-palette.hooks';
import { CollapseSideBar, GraphQLIcon, MenuIcon } from '../../../icons/icons';
import { useSelectedOrganizationStore } from '../../../stores/organization-store';
import { AccountMenu } from '../../account-menu/account-menu.component';
import { AutoCompleteSearch } from '../../global-search/global-search.component';
import { OrganizationSelector } from '../../organizations/organization-selector/organization-selector.component';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import { HelpMenu } from '../help-menu/help-menu.component';
import { ModerneAppBar } from '../top-nav/top-nav.styled';
import { AdminMenu } from './admin-menu-item.component';
import { LeftNavigationMenuItem } from './left-nav-menu-item.component';
import { getLeftNavMenuItems } from './navigation-items';

export const LeftNavigation: FunctionComponent = () => {
  const { isLargeDevice } = useCommonBreakpoints();
  const { selectedOrganizationId } = useSelectedOrganizationStore();
  const [expandLeftNav, setExpandLeftNav] = useState(true);
  const router = useRouter();

  const openNav = () => setExpandLeftNav(true);
  const closeNav = () => setExpandLeftNav(false);
  const toggleMenu = () => setExpandLeftNav(!expandLeftNav);

  const leftNavMenuItems = getLeftNavMenuItems({ selectedOrganizationId });

  useEffect(() => {
    if (isLargeDevice) {
      openNav();
    } else if (!isLargeDevice && open) {
      closeNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargeDevice]);

  useCommandPalette([
    {
      label: 'Zen mode',
      action: closeNav
    },
    {
      label: 'Exit zen mode',
      action: openNav
    },
    {
      label: 'Go to Home',
      action: () => router.push(HOME_PAGE_PATH),
      keyboardShortcuts: {
        key: 'h'
      }
    },
    {
      label: 'Go to Account settings',
      action: () => router.push(ACCOUNT_SETTINGS_PATH),
      keyboardShortcuts: {
        key: '~',
        modifier: {
          shift: true
        }
      }
    },
    ...leftNavMenuItems.map((i) => ({
      label: `Go to ${i.label}`,
      description: i.description,
      action: () => router.push(determineHref(i.href)),
      keyboardShortcuts: i.shortcut
        ? {
            key: i.shortcut
          }
        : undefined
    }))
  ]);

  return (
    <>
      {!isLargeDevice && <ModerneTopBar toggleMenu={toggleMenu} />}
      <Drawer
        variant={isLargeDevice ? 'permanent' : 'temporary'}
        anchor="left"
        open={isLargeDevice || expandLeftNav}
        onClose={closeNav}
        sx={{
          width: drawerResponsiveWidth(expandLeftNav)
        }}
        SlideProps={{ appear: true }}
        PaperProps={{
          sx: {
            gap: 1.5,
            backgroundColor: (theme) => theme.palette.common.white,
            border: (theme) =>
              `1px ${theme.palette.grey['leftNavRightBorder']} solid`,
            boxShadow: 'none',
            width: drawerResponsiveWidth(expandLeftNav),
            overflowX: expandLeftNav ? undefined : 'hidden'
          }
        }}>
        <Box
          sx={{
            paddingRight: 2.5,
            paddingLeft: 1.25,
            paddingTop: 1.25
          }}>
          <ModerneMenuHeader toggleMenu={toggleMenu} />
        </Box>
        <Stack
          sx={{
            gap: 1.5,
            paddingRight: 2.5,
            paddingLeft: 1.25
          }}>
          <AutoCompleteSearch toggleMenu={!isLargeDevice && toggleMenu} />
          <OrganizationSelector />
        </Stack>
        <List sx={{ width: '100%' }}>
          {leftNavMenuItems.map((menuItem, idx) => (
            <LeftNavigationMenuItem
              key={`fixed-menu-${idx}`}
              menuItem={menuItem}
            />
          ))}
          <AdminMenu />
        </List>
        {/* Spacer */}
        <Box
          sx={{
            flex: 1
          }}
        />
        <List sx={{ width: '100%' }}>
          <LeftNavigationMenuItem
            key={`api-explorer`}
            menuItem={{
              icon: <SvgIcon inheritViewBox component={GraphQLIcon} />,
              href: EXPLORE_API_PATH,
              label: 'API explorer',
              description: 'Find and use GraphQL APIs'
            }}
          />
          <HelpMenu />
          <Divider />
          <AccountMenu />
        </List>
      </Drawer>
    </>
  );
};

type ModerneMenuHeaderProps = {
  toggleMenu: () => void;
};

const ModerneMenuHeader: FunctionComponent<ModerneMenuHeaderProps> = ({
  toggleMenu
}) => {
  const { isLargeDevice } = useCommonBreakpoints();

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 1
      }}>
      <Link title="Moderne" href={HOME_PAGE_PATH}>
        <img src="/moderne-wordmark.svg" alt="Moderne" height={24} />
      </Link>
      {!isLargeDevice && (
        <IconButton onClick={toggleMenu} size="small">
          <CollapseSideBar fontSize="inherit" />
        </IconButton>
      )}
    </Stack>
  );
};

const ModerneTopBar: FunctionComponent<{
  toggleMenu: () => void;
}> = ({ toggleMenu }) => {
  return (
    <ModerneAppBar
      sx={{
        backgroundColor: (theme) => `${theme.palette.grey[100]}97`,
        justifyContent: 'center'
      }}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: { xs: 'space-between', sm: 'start' },
          marginLeft: 1.5,
          gap: 1
        }}>
        <IconButton size="small" onClick={toggleMenu}>
          <MenuIcon fontSize="inherit" />
        </IconButton>
        <Link
          title="Moderne"
          href={HOME_PAGE_PATH}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 23
          }}>
          <img src="/moderne-wordmark.svg" alt="Moderne" />
        </Link>
        <Box
          sx={{
            width: 32
          }}
        />
      </Stack>
    </ModerneAppBar>
  );
};
