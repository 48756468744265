import { Radio } from '@mui/material';
import { FunctionComponent, useCallback, useEffect } from 'react';

import { PureFunction } from '@bonsai-components/utility-types';
import {
  DataGridProProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApiPro,
  GridCallbackDetails,
  GridGroupNode,
  GridRowId,
  GridRowSelectionModel,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useOrganizationSelectorGridLazyQuery } from '../../__generated__/apollo-hooks';

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { hasValidFilterModel } from '../../helpers/data-grid.helper';
import {
  useCreateOrEditUserOrganization,
  useExportUserOrganization,
  useRemoveUserOrganization
} from '../../hooks/use-user-organizations.hooks';
import { useSelectedOrganizationStore } from '../../stores/organization-store';
import { ModerneDataGrid } from '../styled-components/data-grids/data-grids.styled';
import { getOrganizationColumns } from './organizations.columns';
import {
  MY_ORGANIZATIONS_GROUPING,
  getOrganizationRows
} from './organizations.rows';
import { MyOrganizationsToolbar } from './organizations.toolbar.component';

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.path;

const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'Organization',
  minWidth: 200,
  flex: 1,
  disableColumnMenu: false,
  hideDescendantCount: true,
  filterable: true,
  sortable: false,
  pinnable: false,
  sortingOrder: ['asc', 'desc', null]
};

export const OrganizationsTable: FunctionComponent<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const apiRef = useGridApiRef();
  const { selectedOrganizationId, update } = useSelectedOrganizationStore();
  const exportOrganization = useExportUserOrganization();
  const removeOrganizationModal = useRemoveUserOrganization();
  const {
    editUserOrganization,
    copyUserOrganization,
    renderOrganizationModal
  } = useCreateOrEditUserOrganization();

  const [fetchOrganizations, { data, networkStatus }] =
    useOrganizationSelectorGridLazyQuery({
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    });

  const loading = isNetworkRequestInFlight(networkStatus);

  useEffect(() => {
    const fetchAllOrganizations = async () => {
      // use a while loop to call `fetchOrganizations` until the data is loaded
      let hasNextPage = true;
      let cursor = undefined;
      while (hasNextPage) {
        const response = await fetchOrganizations({
          variables: {
            after: cursor
          }
        });

        if (response?.data?.organizations?.pageInfo?.hasNextPage) {
          cursor = response.data.organizations.pageInfo.endCursor;
        } else {
          hasNextPage = false;
        }
      }
    };

    fetchAllOrganizations();
  }, [apiRef, fetchOrganizations]);

  const rows = getOrganizationRows(
    data?.organizations?.edges?.map(({ node }) => node) ?? []
  );
  const columns = getOrganizationColumns({
    ActionColumnProps: {
      copyOrganization: copyUserOrganization,
      editOrganization: editUserOrganization,
      removeOrganizationModal,
      exportOrganization
    }
  });

  const handleRowSelectionModelChange = (
    newModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    if (!details?.reason && newModel.length === 0) {
      return;
    }

    const newSelection = newModel
      ?.filter((id) => id !== selectedOrganizationId)
      .at(0);

    if (newSelection !== selectedOrganizationId) {
      update({ selectedOrganizationId: String(newSelection) });
    }
    onClose && onClose();
  };

  const selectionModel: GridRowSelectionModel = selectedOrganizationId
    ? [selectedOrganizationId]
    : [];

  const getExpansionStatus: PureFunction<GridGroupNode, boolean> = (node) => {
    const selectedRow = rows.find((row) => row.id === selectionModel.at(0));

    // rules:
    // 1. always expand my organizations by default
    // 2. if the current selection is a root node, do not expand
    // 3. if the current selection is a child node, expand all the parent nodes
    if (node.groupingKey === MY_ORGANIZATIONS_GROUPING) {
      return true;
    } else if (node.depth === 0 && selectedRow?.id === node.id) {
      return false;
    } else {
      return selectedRow?.path.some((p) => p === String(node.groupingKey));
    }
  };

  const recursivelyCollapseChildren = useCallback(
    (row: GridRowId, api: GridApiPro) => {
      const node = api.getRowNode(row);
      if (node.type === 'group' && node.childrenExpanded) {
        // collapse the current row
        api.setRowChildrenExpansion(row, false);
        // recursively collapse the children
        node.children.forEach((node) => recursivelyCollapseChildren(node, api));
      }
    },
    []
  );

  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      'rowExpansionChange',
      (params, _event, details) => {
        const { api } = details as { api: GridApiPro };
        if (!params.childrenExpanded) {
          params.children.forEach((node) =>
            recursivelyCollapseChildren(node, api)
          );
        }
      }
    );
  }, [apiRef, recursivelyCollapseChildren]);

  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      'filterModelChange',
      (filterModel, _events, details) => {
        if (hasValidFilterModel(filterModel, details)) {
          const { api } = details as { api: GridApiPro };

          const allRowIds = api.getAllRowIds();
          const filteredRowsLookup = api.state.filter.filteredRowsLookup;
          const rowModels = Array.from(api.getRowModels().values());
          const hasQuickFilter = filterModel.quickFilterValues.length > 0;

          allRowIds.forEach((rowId) => {
            const node = api.getRowNode(rowId);
            if (node.type === 'group' && filteredRowsLookup[rowId]) {
              const selectedRow = rowModels.find(
                (row) => row.id === selectedOrganizationId
              );
              const shouldExpand =
                hasQuickFilter ||
                selectedRow?.path.includes(String(node.groupingKey));

              api.setRowChildrenExpansion(rowId, shouldExpand);
            }
          });
        }
      }
    );
  }, [apiRef, selectedOrganizationId]);

  return (
    <>
      <ModerneDataGrid
        // disable options
        disableColumnReorder
        disableColumnSelector
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        autoPageSize={false}
        // data config
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        // loading config
        loading={loading}
        // pagination config
        pagination={false}
        // tree config
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        isGroupExpandedByDefault={getExpansionStatus}
        // selection config
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        isRowSelectable={(params) =>
          params.row.id !== MY_ORGANIZATIONS_GROUPING
        }
        initialState={{
          pinnedColumns: {
            left: [
              GRID_ACTIONS_COLUMN_TYPE,
              GRID_CHECKBOX_SELECTION_COL_DEF.field
            ]
          },
          sorting: {
            sortModel: [{ field: 'isOrganization', sort: 'desc' }]
          },
          columns: {
            columnVisibilityModel: {
              isOrganization: false,
              name: false
            }
          }
        }}
        slots={{
          toolbar: MyOrganizationsToolbar,
          baseCheckbox: Radio,
          footer: () => null
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'linear-progress'
          },
          baseCheckbox: {
            size: 'small'
          }
        }}
        sx={{
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none'
            }
          }
        }}
      />

      {renderOrganizationModal}
    </>
  );
};
