import { KNOWN_SIGN_IN_ERRORS } from './messages';

export const SUPPORT_EMAIL = 'support@moderne.io';

// local storage keys
export const LOCAL_STORAGE_RECIPE = 'recipe';
export const LOCAL_STORAGE_RESULT_GRID_MODELS = 'result-grid-models';
export const LOCAL_STORAGE_USER_PREFERENCES = 'user-preferences';
export const SESSION_STORAGE_SELECTED_REPOSITORY_GROUP =
  'selectedRepositoryGroup';

export const SHOW_ALL_REPOS = 'showAllRepos';

// site metadata
export const SITE_TITLE = 'Moderne';
export const SITE_TAG_LINE =
  'Automatically fix, upgrade, and secure code in minutes, not months.';

// user experience
export const TOP_BANNER_HEIGHT = 45;
export const BRANDING_HEIGHT = 30;
export const BRANDING_WIDTH = BRANDING_HEIGHT * 4;
export const TENANT_BRANDING_ASPECT_RATIO = 2.5;

// Pagination
export const PAGE_SIZE_DEFAULT = 100;
export const PAGE_SIZE_AGENT = 5;
export const PAGE_SIZE_COMMIT_JOB = 50;

/**
 * Max threshold a user should have to wait for some ui response their action
 */
export const DOHERTY_THRESHOLD = 400;

export const RECIPE_RUN_POLL_TIME = 3000;

export const SONAR_ISSUE_PREFIX = 'RSPEC-';

// GitHub Integration
export const DEFAULT_CUSTOMER_SUPPORT_ORG = 'moderneinc';
export const DEFAULT_OPENREWRITE_SUPPORT_ORG = 'openrewrite';
export const GITHUB_PUBLIC_HOST = 'https://github.com';

// External URLs
export const CHANGELOG_PAGE_URL = 'https://docs.moderne.io/releases/changelog';
export const CORPORATE_SITE_URL = 'https://moderne.ai';
export const DOCUMENTATION_SITE_URL = 'https://docs.moderne.io';
export const OPENREWRITE_SITE_URL = 'https://docs.openrewrite.org';
export const NEWSLETTER_SIGN_UP_URL =
  'https://www.moderne.ai/newsletter-sign-up';

// Internal URLs
export const ACCOUNT_SETTINGS_PATH = '/settings';
export const EXPLORE_API_PATH = '/graphql';
export const HOME_PAGE_PATH = '/';
export const MARKETPLACE_HOME_PATH = '/marketplace';
export const DEVCENTER_PATH = '/devcenter';
export const MARKETPLACE_RECIPES_PATH = '/marketplace';
export const ORGANIZATION_REPOSITORIES_BASE_PATH = '/organizations';
export const ORGANIZATIONS_PATH = '/organizations';
export const RECENT_ACTIVITY_PATH = '/recent-activity';
export const DEPLOYMENT_PATH = '/recipes/deploy';
export const NEW_BUILDER_PATH = '/builder';

// Admin URLs
export const ADMIN_ACCESS_TOKENS_PATH = '/admin/access-tokens';
export const AUDIT_LOGS_PATH = '/admin/audit-logs';
export const INTEGRATIONS_PATH = '/admin/agents';
export const QUARANTINED_DASHBOARD_PATH = '/admin/quarantined';
export const REPORTS_PATH = '/admin/reports';
export const USERS_PATH = '/admin/users';
export const WORKERS_DASHBOARD_PATH = '/admin/workers';

// Error URLs
export const INVALID_TOKEN_ERROR_PATH = `/auth/signin?error=${KNOWN_SIGN_IN_ERRORS.invalidToken}`;

// Aliases
export const INTEGRATIONS_ALIAS = 'Agents';

// Breadcrumb labels
export const RECENT_ACTIVITY_LABEL = 'Recent activity';

/**
 * Targets DGS exception preamble
 * example:
 * com.netflix.graphql.dgs.exceptions.DgsEntityNotFoundException:
 */
export const DGS_ERROR_PREAMBLE_REGEX =
  /com\.netflix\.graphql\.dgs\.exceptions\..*?\s/;

export const GRAPHQL_INTERNAL_ERROR = /graphql/;

/**
 * Common HTML Entities found in product titles
 */
export const ENTITY_TO_UNICODE = {
  '&reg;': '\u00ae',
  '&eacute;': '\u00e9',
  '&quot;': '\u0022',
  '&trade;': '\u2122',
  '&amp;': '\u0026',
  '&deg;': '\u00b0',
  '&apos;': '\u0027',
  '&ouml': '\u00f6',
  '&gt;': '\u003e',
  '&nbsp;': '\u00a0',
  '&copy': '\u00a9',
  '&ldquo;': '\u201c',
  '&rdquo;': '\u201d'
};

export const DRAWER_WIDTH_EXPANDED = 250;
const DRAWER_WIDTH_COLLAPSED = 0;
export const GRAPHQL_POLLING_INTERVAL = 3000;

export const drawerResponsiveWidth = (expandLeftNav: boolean) => [
  expandLeftNav ? '100%' : DRAWER_WIDTH_COLLAPSED,
  expandLeftNav ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED
];

// global element IDs
export const SEARCH_INPUT_ID = 'nav-search-bar-input';

export const GOTO_ENTITY_DETAILS = 'See details';

// DevCenter
export const MODERNE_CHART_COLOR_SCALE = {
  digitalBlue: `#2F42FF99`,
  periwinkle: `#7E9BD399`,
  activityGreen: `#27AA8899`,
  sage: `#DCEFDE99`,
  activityGreenLite: `#7DCCB899`,
  amber: `#F9A91B99`,
  magenta: `#DB419799`,
  lavender: `#992FB999`,
  digitalBlueLite: `#D5D9FF99`,
  cherryRed: `#FF323299`
};
export const MODERNE_CHART_WIDTH = 300;
export const MODERNE_CHART_HEIGHT = 300;

// Builder v2
export const BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH = '32%';
