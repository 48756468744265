import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type AccessTokenKeySpecifier = ('created' | 'description' | 'id' | AccessTokenKeySpecifier)[];
export type AccessTokenFieldPolicy = {
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AgentKeySpecifier = ('id' | 'nickname' | 'tenantDomain' | 'tools' | 'version' | AgentKeySpecifier)[];
export type AgentFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	nickname?: FieldPolicy<any> | FieldReadFunction<any>,
	tenantDomain?: FieldPolicy<any> | FieldReadFunction<any>,
	tools?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AgentConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | AgentConnectionKeySpecifier)[];
export type AgentConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AgentEdgeKeySpecifier = ('cursor' | 'node' | AgentEdgeKeySpecifier)[];
export type AgentEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ApproveActionKeySpecifier = ('createdAt' | 'pullRequestUrl' | 'repository' | 'state' | 'stateMessage' | 'updatedAt' | ApproveActionKeySpecifier)[];
export type ApproveActionFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArtifactDiagnosticsKeySpecifier = ('allArtifacts' | 'allArtifactsByLocation' | 'artifactsToDownload' | 'indexableRepositories' | 'latestMavenArtifactByLocation' | 'latestMavenArtifacts' | 'listUpdates' | 'mavenIndexProperties' | 'repositoryCount' | ArtifactDiagnosticsKeySpecifier)[];
export type ArtifactDiagnosticsFieldPolicy = {
	allArtifacts?: FieldPolicy<any> | FieldReadFunction<any>,
	allArtifactsByLocation?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactsToDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	indexableRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	latestMavenArtifactByLocation?: FieldPolicy<any> | FieldReadFunction<any>,
	latestMavenArtifacts?: FieldPolicy<any> | FieldReadFunction<any>,
	listUpdates?: FieldPolicy<any> | FieldReadFunction<any>,
	mavenIndexProperties?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoryCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArtifactStatsKeySpecifier = ('astsPublished' | 'date' | ArtifactStatsKeySpecifier)[];
export type ArtifactStatsFieldPolicy = {
	astsPublished?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArtifactoryConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'artifactStats' | 'astQuery' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | ArtifactoryConfigurationKeySpecifier)[];
export type ArtifactoryConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactStats?: FieldPolicy<any> | FieldReadFunction<any>,
	astQuery?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstKeySpecifier = ('artifactRepository' | 'location' | 'modified' | 'weight' | AstKeySpecifier)[];
export type AstFieldPolicy = {
	artifactRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstArtifactKeySpecifier = ('location' | 'timestamp' | 'url' | AstArtifactKeySpecifier)[];
export type AstArtifactFieldPolicy = {
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	timestamp?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstArtifactRepositoryKeySpecifier = ('artifactLocation' | 'artifactModified' | 'artifactRepository' | 'branch' | 'buildId' | 'buildPluginName' | 'buildPluginVersion' | 'changeset' | 'origin' | 'path' | AstArtifactRepositoryKeySpecifier)[];
export type AstArtifactRepositoryFieldPolicy = {
	artifactLocation?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactModified?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	buildId?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginName?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstArtifactRepositoryDiagnosticKeySpecifier = ('artifactLocation' | 'artifactModified' | 'artifactRepository' | 'branch' | 'buildId' | 'buildPluginName' | 'buildPluginVersion' | 'changeset' | 'isDownloaded' | 'origin' | 'path' | AstArtifactRepositoryDiagnosticKeySpecifier)[];
export type AstArtifactRepositoryDiagnosticFieldPolicy = {
	artifactLocation?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactModified?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	buildId?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginName?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	isDownloaded?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | AstConnectionKeySpecifier)[];
export type AstConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstEdgeKeySpecifier = ('cursor' | 'node' | AstEdgeKeySpecifier)[];
export type AstEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AstRepositoryKeySpecifier = ('newestArtifactModifiedTime' | 'uri' | AstRepositoryKeySpecifier)[];
export type AstRepositoryFieldPolicy = {
	newestArtifactModifiedTime?: FieldPolicy<any> | FieldReadFunction<any>,
	uri?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AzureDevOpsConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isAuthorized' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'oauth' | 'resourceId' | 'tokens' | 'valid' | AzureDevOpsConfigurationKeySpecifier)[];
export type AzureDevOpsConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	oauth?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AzureDevOpsRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'origin' | 'path' | 'project' | 'weight' | AzureDevOpsRepositoryKeySpecifier)[];
export type AzureDevOpsRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BaseRepositoryKeySpecifier = ('branch' | 'origin' | 'path' | BaseRepositoryKeySpecifier)[];
export type BaseRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BitbucketCloudConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isAuthorized' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'oauth' | 'resourceId' | 'tokens' | 'valid' | BitbucketCloudConfigurationKeySpecifier)[];
export type BitbucketCloudConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	oauth?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BitbucketCloudRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'origin' | 'path' | 'project' | 'weight' | BitbucketCloudRepositoryKeySpecifier)[];
export type BitbucketCloudRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BitbucketConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isAuthorized' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'ssh' | 'tokens' | 'valid' | BitbucketConfigurationKeySpecifier)[];
export type BitbucketConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	ssh?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BitbucketRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'origin' | 'path' | 'project' | 'weight' | BitbucketRepositoryKeySpecifier)[];
export type BitbucketRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BranchCommitOptionsKeySpecifier = ('branchName' | BranchCommitOptionsKeySpecifier)[];
export type BranchCommitOptionsFieldPolicy = {
	branchName?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CloseActionKeySpecifier = ('createdAt' | 'pullRequestUrl' | 'repository' | 'state' | 'stateMessage' | 'updatedAt' | CloseActionKeySpecifier)[];
export type CloseActionFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ColumnKeySpecifier = ('description' | 'displayName' | 'name' | 'type' | ColumnKeySpecifier)[];
export type ColumnFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitKeySpecifier = ('commitType' | 'createdAt' | 'modified' | 'pullRequestStatus' | 'repository' | 'resultLink' | 'state' | 'stateMessage' | 'updatedAt' | CommitKeySpecifier)[];
export type CommitFieldPolicy = {
	commitType?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	resultLink?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | CommitConnectionKeySpecifier)[];
export type CommitConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitEdgeKeySpecifier = ('cursor' | 'node' | CommitEdgeKeySpecifier)[];
export type CommitEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitJobKeySpecifier = ('commits' | 'completed' | 'email' | 'excludedFiles' | 'extendedMessage' | 'id' | 'message' | 'options' | 'organization' | 'pullRequestActionJobs' | 'recipeRunId' | 'started' | 'state' | 'summaryResults' | CommitJobKeySpecifier)[];
export type CommitJobFieldPolicy = {
	commits?: FieldPolicy<any> | FieldReadFunction<any>,
	completed?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	excludedFiles?: FieldPolicy<any> | FieldReadFunction<any>,
	extendedMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestActionJobs?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRunId?: FieldPolicy<any> | FieldReadFunction<any>,
	started?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	summaryResults?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitJobConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | CommitJobConnectionKeySpecifier)[];
export type CommitJobConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitJobEdgeKeySpecifier = ('cursor' | 'node' | CommitJobEdgeKeySpecifier)[];
export type CommitJobEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitJobSummaryKeySpecifier = ('canceledCount' | 'count' | 'failedCount' | 'noChangeCount' | 'successfulCount' | CommitJobSummaryKeySpecifier)[];
export type CommitJobSummaryFieldPolicy = {
	canceledCount?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	failedCount?: FieldPolicy<any> | FieldReadFunction<any>,
	noChangeCount?: FieldPolicy<any> | FieldReadFunction<any>,
	successfulCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitOptionsKeySpecifier = ('branchName' | CommitOptionsKeySpecifier)[];
export type CommitOptionsFieldPolicy = {
	branchName?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CommitsReportDownloadTaskKeySpecifier = ('fileSize' | 'id' | 'state' | 'stateMessage' | 'url' | CommitsReportDownloadTaskKeySpecifier)[];
export type CommitsReportDownloadTaskFieldPolicy = {
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContributorKeySpecifier = ('email' | 'lineCount' | 'name' | ContributorKeySpecifier)[];
export type ContributorFieldPolicy = {
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	lineCount?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CustomRecipeKeySpecifier = ('description' | 'id' | 'name' | 'yaml' | CustomRecipeKeySpecifier)[];
export type CustomRecipeFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	yaml?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DashboardKeySpecifier = ('devCenterVersion' | 'fallback' | 'noLstCount' | 'organizationStats' | 'security' | 'upgradesAndMigrations' | 'visualizations' | DashboardKeySpecifier)[];
export type DashboardFieldPolicy = {
	devCenterVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	fallback?: FieldPolicy<any> | FieldReadFunction<any>,
	noLstCount?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationStats?: FieldPolicy<any> | FieldReadFunction<any>,
	security?: FieldPolicy<any> | FieldReadFunction<any>,
	upgradesAndMigrations?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTableKeySpecifier = ('columns' | 'description' | 'displayName' | 'name' | DataTableKeySpecifier)[];
export type DataTableFieldPolicy = {
	columns?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTableDevCenterDownloadTaskKeySpecifier = ('downloadUrl' | 'id' | 'organizationId' | 'state' | 'stateMessage' | DataTableDevCenterDownloadTaskKeySpecifier)[];
export type DataTableDevCenterDownloadTaskFieldPolicy = {
	downloadUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTableDownloadStatsKeySpecifier = ('fileSize' | 'repositories' | DataTableDownloadStatsKeySpecifier)[];
export type DataTableDownloadStatsFieldPolicy = {
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTableDownloadTaskKeySpecifier = ('dataTable' | 'downloadUrl' | 'format' | 'id' | 'recipeRunId' | 'state' | 'stateMessage' | 'stats' | DataTableDownloadTaskKeySpecifier)[];
export type DataTableDownloadTaskFieldPolicy = {
	dataTable?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	format?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRunId?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	stats?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EventKeySpecifier = ('action' | 'actionType' | 'description' | 'outcome' | 'target' | 'timestamp' | 'userId' | EventKeySpecifier)[];
export type EventFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>,
	actionType?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	outcome?: FieldPolicy<any> | FieldReadFunction<any>,
	target?: FieldPolicy<any> | FieldReadFunction<any>,
	timestamp?: FieldPolicy<any> | FieldReadFunction<any>,
	userId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EventConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | EventConnectionKeySpecifier)[];
export type EventConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EventEdgeKeySpecifier = ('cursor' | 'node' | EventEdgeKeySpecifier)[];
export type EventEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ForkCommitOptionsKeySpecifier = ('branchName' | 'organization' | 'prefixOrganization' | ForkCommitOptionsKeySpecifier)[];
export type ForkCommitOptionsFieldPolicy = {
	branchName?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	prefixOrganization?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ForkPullRequestOptionsKeySpecifier = ('branchName' | 'canRecreateClosedPullRequest' | 'draft' | 'maintainerCanModify' | 'organization' | 'prefixOrganization' | 'pullRequestBody' | 'pullRequestTitle' | ForkPullRequestOptionsKeySpecifier)[];
export type ForkPullRequestOptionsFieldPolicy = {
	branchName?: FieldPolicy<any> | FieldReadFunction<any>,
	canRecreateClosedPullRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	draft?: FieldPolicy<any> | FieldReadFunction<any>,
	maintainerCanModify?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	prefixOrganization?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestBody?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestTitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GenericHttpToolConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | GenericHttpToolConfigurationKeySpecifier)[];
export type GenericHttpToolConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GitHubRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'organization' | 'origin' | 'path' | 'weight' | GitHubRepositoryKeySpecifier)[];
export type GitHubRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GitLabConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isAuthorized' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'oauth' | 'resourceId' | 'ssh' | 'tokens' | 'valid' | GitLabConfigurationKeySpecifier)[];
export type GitLabConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	oauth?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	ssh?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GitLabRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'organization' | 'origin' | 'path' | 'projectNamespace' | 'weight' | GitLabRepositoryKeySpecifier)[];
export type GitLabRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	projectNamespace?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GithubConfigurationKeySpecifier = ('agents' | 'allowableOrganizations' | 'alternateUrls' | 'canConnect' | 'id' | 'isAuthorized' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'oauth' | 'resourceId' | 'ssh' | 'tokens' | 'valid' | GithubConfigurationKeySpecifier)[];
export type GithubConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	allowableOrganizations?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	oauth?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	ssh?: FieldPolicy<any> | FieldReadFunction<any>,
	tokens?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupArtifactVersionKeySpecifier = ('artifactId' | 'datedSnapshotVersion' | 'groupId' | 'version' | GroupArtifactVersionKeySpecifier)[];
export type GroupArtifactVersionFieldPolicy = {
	artifactId?: FieldPolicy<any> | FieldReadFunction<any>,
	datedSnapshotVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	groupId?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>
};
export type JenkinsConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | JenkinsConfigurationKeySpecifier)[];
export type JenkinsConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LineCountPerRepositoryKeySpecifier = ('lineCount' | 'repository' | LineCountPerRepositoryKeySpecifier)[];
export type LineCountPerRepositoryFieldPolicy = {
	lineCount?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MaintainerKeySpecifier = ('logo' | 'name' | MaintainerKeySpecifier)[];
export type MaintainerFieldPolicy = {
	logo?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MavenConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'artifactStats' | 'canConnect' | 'id' | 'isArtifactSource' | 'isRecipeSource' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'localRepository' | 'mavenResponseStatus' | 'releases' | 'resourceId' | 'snapshots' | 'valid' | MavenConfigurationKeySpecifier)[];
export type MavenConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactStats?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isArtifactSource?: FieldPolicy<any> | FieldReadFunction<any>,
	isRecipeSource?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	localRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	mavenResponseStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	releases?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	snapshots?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MavenIndexPropertyKeySpecifier = ('properties' | 'url' | MavenIndexPropertyKeySpecifier)[];
export type MavenIndexPropertyFieldPolicy = {
	properties?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MavenResponseStatusKeySpecifier = ('responseCode' | 'supported' | 'unsupportedReasons' | MavenResponseStatusKeySpecifier)[];
export type MavenResponseStatusFieldPolicy = {
	responseCode?: FieldPolicy<any> | FieldReadFunction<any>,
	supported?: FieldPolicy<any> | FieldReadFunction<any>,
	unsupportedReasons?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MeasureKeySpecifier = ('name' | 'recipe' | 'repositoriesChanged' | MeasureKeySpecifier)[];
export type MeasureFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoriesChanged?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MergeActionKeySpecifier = ('createdAt' | 'options' | 'pullRequestUrl' | 'repository' | 'state' | 'stateMessage' | 'updatedAt' | MergeActionKeySpecifier)[];
export type MergeActionFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MergeOptionsKeySpecifier = ('deleteSourceBranch' | 'mergeMethod' | MergeOptionsKeySpecifier)[];
export type MergeOptionsFieldPolicy = {
	deleteSourceBranch?: FieldPolicy<any> | FieldReadFunction<any>,
	mergeMethod?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('approvePullRequests' | 'cancelAllActiveRuns' | 'cancelAllActiveRunsForUser' | 'cancelCommitJob' | 'cancelPullRequestActionJob' | 'cancelRecipeRun' | 'cancelRecipeRunValidation' | 'cancelVisualizationRun' | 'closePullRequests' | 'commit' | 'copyRecipeRunsToHistory' | 'createAccessToken' | 'createUserOrganization' | 'deleteAllAccessTokens' | 'deleteAsts' | 'deleteRecipeArtifact' | 'deleteRecipeRunHistory' | 'deleteRepositoriesByUrl' | 'deleteRepository' | 'deleteRepositoryByLocation' | 'deleteUserOrganization' | 'deleteVisualizationArtifact' | 'downloadCommitsReport' | 'downloadDataTable' | 'downloadDevCenterDataTable' | 'downloadRecipeRunReport' | 'exchangeAzureDevOpsAuthorizationCode' | 'exchangeBitbucketAuthorizationVerifier' | 'exchangeBitbucketCloudAuthorizationCode' | 'exchangeGitLabAuthorizationCode' | 'exchangeGithubAuthorizationCode' | 'flushAgentMavenPomCache' | 'forkAndCommit' | 'forkAndPullRequest' | 'getBitbucketRequestToken' | 'index' | 'loadRecipesAsync' | 'loadVisualizations' | 'loadVisualizationsAsync' | 'mergePullRequests' | 'pullRequest' | 'quarantineRepository' | 'queueRecipeRunValidation' | 'refreshOrganizations' | 'removeRepositoriesFromUserOrganization' | 'reportModerneCliBuildResult' | 'rerunFailedCommitJob' | 'rerunFailedPullRequestActionJob' | 'revokeAccessToken' | 'revokeAllScmTokens' | 'revokeBitbucketAccessToken' | 'revokeScmToken' | 'runRecipe' | 'runVisualization' | 'runYamlRecipe' | 'unquarantineRepository' | 'updateRecipes' | 'updateUserOrganization' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	approvePullRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelAllActiveRuns?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelAllActiveRunsForUser?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelCommitJob?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelPullRequestActionJob?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelRecipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelRecipeRunValidation?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelVisualizationRun?: FieldPolicy<any> | FieldReadFunction<any>,
	closePullRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	commit?: FieldPolicy<any> | FieldReadFunction<any>,
	copyRecipeRunsToHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	createAccessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	createUserOrganization?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAllAccessTokens?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAsts?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRecipeArtifact?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRecipeRunHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRepositoriesByUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRepositoryByLocation?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUserOrganization?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteVisualizationArtifact?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadCommitsReport?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadDataTable?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadDevCenterDataTable?: FieldPolicy<any> | FieldReadFunction<any>,
	downloadRecipeRunReport?: FieldPolicy<any> | FieldReadFunction<any>,
	exchangeAzureDevOpsAuthorizationCode?: FieldPolicy<any> | FieldReadFunction<any>,
	exchangeBitbucketAuthorizationVerifier?: FieldPolicy<any> | FieldReadFunction<any>,
	exchangeBitbucketCloudAuthorizationCode?: FieldPolicy<any> | FieldReadFunction<any>,
	exchangeGitLabAuthorizationCode?: FieldPolicy<any> | FieldReadFunction<any>,
	exchangeGithubAuthorizationCode?: FieldPolicy<any> | FieldReadFunction<any>,
	flushAgentMavenPomCache?: FieldPolicy<any> | FieldReadFunction<any>,
	forkAndCommit?: FieldPolicy<any> | FieldReadFunction<any>,
	forkAndPullRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	getBitbucketRequestToken?: FieldPolicy<any> | FieldReadFunction<any>,
	index?: FieldPolicy<any> | FieldReadFunction<any>,
	loadRecipesAsync?: FieldPolicy<any> | FieldReadFunction<any>,
	loadVisualizations?: FieldPolicy<any> | FieldReadFunction<any>,
	loadVisualizationsAsync?: FieldPolicy<any> | FieldReadFunction<any>,
	mergePullRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	quarantineRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	queueRecipeRunValidation?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshOrganizations?: FieldPolicy<any> | FieldReadFunction<any>,
	removeRepositoriesFromUserOrganization?: FieldPolicy<any> | FieldReadFunction<any>,
	reportModerneCliBuildResult?: FieldPolicy<any> | FieldReadFunction<any>,
	rerunFailedCommitJob?: FieldPolicy<any> | FieldReadFunction<any>,
	rerunFailedPullRequestActionJob?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeAccessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeAllScmTokens?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeBitbucketAccessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeScmToken?: FieldPolicy<any> | FieldReadFunction<any>,
	runRecipe?: FieldPolicy<any> | FieldReadFunction<any>,
	runVisualization?: FieldPolicy<any> | FieldReadFunction<any>,
	runYamlRecipe?: FieldPolicy<any> | FieldReadFunction<any>,
	unquarantineRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	updateRecipes?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserOrganization?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NugetConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'artifactStats' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'localRepository' | 'resourceId' | 'valid' | NugetConfigurationKeySpecifier)[];
export type NugetConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactStats?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	localRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OAuthConfigurationKeySpecifier = ('clientId' | 'includePrivateRepos' | 'tenantId' | OAuthConfigurationKeySpecifier)[];
export type OAuthConfigurationFieldPolicy = {
	clientId?: FieldPolicy<any> | FieldReadFunction<any>,
	includePrivateRepos?: FieldPolicy<any> | FieldReadFunction<any>,
	tenantId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OptionKeySpecifier = ('description' | 'displayName' | 'example' | 'name' | 'required' | 'type' | 'valid' | 'value' | OptionKeySpecifier)[];
export type OptionFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	example?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	required?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationKeySpecifier = ('commitOptions' | 'dashboard' | 'description' | 'id' | 'isUserOrganization' | 'name' | 'parent' | 'repositoriesPages' | 'statistics' | OrganizationKeySpecifier)[];
export type OrganizationFieldPolicy = {
	commitOptions?: FieldPolicy<any> | FieldReadFunction<any>,
	dashboard?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isUserOrganization?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	parent?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoriesPages?: FieldPolicy<any> | FieldReadFunction<any>,
	statistics?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | OrganizationConfigurationKeySpecifier)[];
export type OrganizationConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | OrganizationConnectionKeySpecifier)[];
export type OrganizationConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationEdgeKeySpecifier = ('cursor' | 'node' | OrganizationEdgeKeySpecifier)[];
export type OrganizationEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationStatisticCardKeySpecifier = ('label' | 'name' | 'value' | OrganizationStatisticCardKeySpecifier)[];
export type OrganizationStatisticCardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrganizationSummaryKeySpecifier = ('id' | 'name' | OrganizationSummaryKeySpecifier)[];
export type OrganizationSummaryFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrphanedRepositoryKeySpecifier = ('branch' | 'changeset' | 'id' | 'ingested' | 'name' | 'organization' | 'origin' | 'path' | 'weight' | OrphanedRepositoryKeySpecifier)[];
export type OrphanedRepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageKeySpecifier = ('endCursor' | 'hasNextPage' | PageKeySpecifier)[];
export type PageFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionKeySpecifier = ('createdAt' | 'pullRequestUrl' | 'repository' | 'state' | 'stateMessage' | 'updatedAt' | PullRequestActionKeySpecifier)[];
export type PullRequestActionFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | PullRequestActionConnectionKeySpecifier)[];
export type PullRequestActionConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionEdgeKeySpecifier = ('cursor' | 'node' | PullRequestActionEdgeKeySpecifier)[];
export type PullRequestActionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionJobKeySpecifier = ('commitJobId' | 'completed' | 'createdAt' | 'email' | 'id' | 'state' | 'summaryResults' | 'tasks' | 'type' | PullRequestActionJobKeySpecifier)[];
export type PullRequestActionJobFieldPolicy = {
	commitJobId?: FieldPolicy<any> | FieldReadFunction<any>,
	completed?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	summaryResults?: FieldPolicy<any> | FieldReadFunction<any>,
	tasks?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionJobConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | PullRequestActionJobConnectionKeySpecifier)[];
export type PullRequestActionJobConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionJobEdgeKeySpecifier = ('cursor' | 'node' | PullRequestActionJobEdgeKeySpecifier)[];
export type PullRequestActionJobEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestActionJobSummaryKeySpecifier = ('canceledCount' | 'count' | 'failedCount' | 'successfulCount' | PullRequestActionJobSummaryKeySpecifier)[];
export type PullRequestActionJobSummaryFieldPolicy = {
	canceledCount?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	failedCount?: FieldPolicy<any> | FieldReadFunction<any>,
	successfulCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestOptionsKeySpecifier = ('autoMergeMethod' | 'branchName' | 'canRecreateClosedPullRequest' | 'draft' | 'pullRequestBody' | 'pullRequestTitle' | PullRequestOptionsKeySpecifier)[];
export type PullRequestOptionsFieldPolicy = {
	autoMergeMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	branchName?: FieldPolicy<any> | FieldReadFunction<any>,
	canRecreateClosedPullRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	draft?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestBody?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestTitle?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestStatisticsKeySpecifier = ('date' | 'id' | 'origin' | 'type' | PullRequestStatisticsKeySpecifier)[];
export type PullRequestStatisticsFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PullRequestStatusKeySpecifier = ('ciState' | 'mergeable' | 'otherBlockingReasons' | 'review' | 'state' | PullRequestStatusKeySpecifier)[];
export type PullRequestStatusFieldPolicy = {
	ciState?: FieldPolicy<any> | FieldReadFunction<any>,
	mergeable?: FieldPolicy<any> | FieldReadFunction<any>,
	otherBlockingReasons?: FieldPolicy<any> | FieldReadFunction<any>,
	review?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PypiConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | PypiConfigurationKeySpecifier)[];
export type PypiConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('accessTokenEmails' | 'accessTokens' | 'activeRecipeRuns' | 'agents' | 'allCommitJobs' | 'allOrganizations' | 'allRecipeRuns' | 'allVisualizationRunHistory' | 'artifactDiagnostics' | 'availableRecipeRun' | 'availableVisualizationRun' | 'canDeploy' | 'categories' | 'commitJob' | 'commitJobs' | 'commitsReportDownload' | 'customRecipeForRecipeRun' | 'dataTableDownload' | 'dataTableRepositoryOrigins' | 'defaultCommitOptions' | 'devCenterDataTableDownload' | 'events' | 'findRepositories' | 'findRepository' | 'httpTool' | 'indexableRepositories' | 'latestBuild' | 'latestRecipeDeployments' | 'organization' | 'organizationExists' | 'organizationSummaries' | 'organizations' | 'organizationsPages' | 'previousRecipeRuns' | 'pullRequestActionJob' | 'pullRequestStatistics' | 'quarantinedRepositories' | 'recipe' | 'recipeArtifacts' | 'recipeDeployment' | 'recipeDeployments' | 'recipeRun' | 'recipeRunReportDownload' | 'recipeRunSummaryByRepository' | 'repositories' | 'repositoryIndex' | 'scm' | 'scmConfigurations' | 'scms' | 'searchRecipes' | 'statistics' | 'tools' | 'topRepositoriesByLineCount' | 'userHasAccessToRepository' | 'users' | 'verifyToken' | 'visualization' | 'visualizationCategories' | 'visualizationDeployment' | 'visualizationDeployments' | 'visualizationRun' | 'visualizationRunHistory' | 'worker' | 'workers' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	accessTokenEmails?: FieldPolicy<any> | FieldReadFunction<any>,
	accessTokens?: FieldPolicy<any> | FieldReadFunction<any>,
	activeRecipeRuns?: FieldPolicy<any> | FieldReadFunction<any>,
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	allCommitJobs?: FieldPolicy<any> | FieldReadFunction<any>,
	allOrganizations?: FieldPolicy<any> | FieldReadFunction<any>,
	allRecipeRuns?: FieldPolicy<any> | FieldReadFunction<any>,
	allVisualizationRunHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	artifactDiagnostics?: FieldPolicy<any> | FieldReadFunction<any>,
	availableRecipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	availableVisualizationRun?: FieldPolicy<any> | FieldReadFunction<any>,
	canDeploy?: FieldPolicy<any> | FieldReadFunction<any>,
	categories?: FieldPolicy<any> | FieldReadFunction<any>,
	commitJob?: FieldPolicy<any> | FieldReadFunction<any>,
	commitJobs?: FieldPolicy<any> | FieldReadFunction<any>,
	commitsReportDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	customRecipeForRecipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	dataTableDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	dataTableRepositoryOrigins?: FieldPolicy<any> | FieldReadFunction<any>,
	defaultCommitOptions?: FieldPolicy<any> | FieldReadFunction<any>,
	devCenterDataTableDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	events?: FieldPolicy<any> | FieldReadFunction<any>,
	findRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	findRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	httpTool?: FieldPolicy<any> | FieldReadFunction<any>,
	indexableRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	latestBuild?: FieldPolicy<any> | FieldReadFunction<any>,
	latestRecipeDeployments?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationExists?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationSummaries?: FieldPolicy<any> | FieldReadFunction<any>,
	organizations?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationsPages?: FieldPolicy<any> | FieldReadFunction<any>,
	previousRecipeRuns?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestActionJob?: FieldPolicy<any> | FieldReadFunction<any>,
	pullRequestStatistics?: FieldPolicy<any> | FieldReadFunction<any>,
	quarantinedRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeArtifacts?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeDeployment?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeDeployments?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRunReportDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRunSummaryByRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoryIndex?: FieldPolicy<any> | FieldReadFunction<any>,
	scm?: FieldPolicy<any> | FieldReadFunction<any>,
	scmConfigurations?: FieldPolicy<any> | FieldReadFunction<any>,
	scms?: FieldPolicy<any> | FieldReadFunction<any>,
	searchRecipes?: FieldPolicy<any> | FieldReadFunction<any>,
	statistics?: FieldPolicy<any> | FieldReadFunction<any>,
	tools?: FieldPolicy<any> | FieldReadFunction<any>,
	topRepositoriesByLineCount?: FieldPolicy<any> | FieldReadFunction<any>,
	userHasAccessToRepository?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	verifyToken?: FieldPolicy<any> | FieldReadFunction<any>,
	visualization?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationCategories?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationDeployment?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationDeployments?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationRun?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationRunHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	worker?: FieldPolicy<any> | FieldReadFunction<any>,
	workers?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeKeySpecifier = ('contributors' | 'dataTables' | 'description' | 'id' | 'maintainers' | 'name' | 'options' | 'permalink' | 'recipeArtifact' | 'recipeList' | 'sourceUrl' | 'tags' | 'timeSavings' | 'totalRecipes' | 'visualizations' | 'yaml' | RecipeKeySpecifier)[];
export type RecipeFieldPolicy = {
	contributors?: FieldPolicy<any> | FieldReadFunction<any>,
	dataTables?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	maintainers?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	permalink?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeArtifact?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeList?: FieldPolicy<any> | FieldReadFunction<any>,
	sourceUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	timeSavings?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRecipes?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizations?: FieldPolicy<any> | FieldReadFunction<any>,
	yaml?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeArtifactKeySpecifier = ('artifactId' | 'datedSnapshotVersion' | 'groupId' | 'recipeCount' | 'repositoryUrl' | 'requestedVersion' | 'snapshotTime' | 'transitiveDependencyVersions' | 'version' | RecipeArtifactKeySpecifier)[];
export type RecipeArtifactFieldPolicy = {
	artifactId?: FieldPolicy<any> | FieldReadFunction<any>,
	datedSnapshotVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	groupId?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeCount?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoryUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	requestedVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	snapshotTime?: FieldPolicy<any> | FieldReadFunction<any>,
	transitiveDependencyVersions?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeCategoryKeySpecifier = ('breadcrumbs' | 'categories' | 'description' | 'id' | 'name' | 'recipes' | 'tags' | 'totalRecipeCount' | RecipeCategoryKeySpecifier)[];
export type RecipeCategoryFieldPolicy = {
	breadcrumbs?: FieldPolicy<any> | FieldReadFunction<any>,
	categories?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	recipes?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRecipeCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeCategoryBreadcrumbKeySpecifier = ('id' | 'name' | RecipeCategoryBreadcrumbKeySpecifier)[];
export type RecipeCategoryBreadcrumbFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | RecipeConnectionKeySpecifier)[];
export type RecipeConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeDeploymentResultKeySpecifier = ('artifact' | 'canDeploy' | 'duration' | 'id' | 'start' | 'state' | 'stateMessage' | 'tenantDomain' | 'trace' | RecipeDeploymentResultKeySpecifier)[];
export type RecipeDeploymentResultFieldPolicy = {
	artifact?: FieldPolicy<any> | FieldReadFunction<any>,
	canDeploy?: FieldPolicy<any> | FieldReadFunction<any>,
	duration?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	start?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	tenantDomain?: FieldPolicy<any> | FieldReadFunction<any>,
	trace?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeEdgeKeySpecifier = ('cursor' | 'node' | RecipeEdgeKeySpecifier)[];
export type RecipeEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunKeySpecifier = ('commitJobs' | 'email' | 'end' | 'id' | 'organization' | 'organizationId' | 'priority' | 'recipe' | 'start' | 'state' | 'summaryResultsPages' | 'totals' | 'user' | 'visualizationRuns' | RecipeRunKeySpecifier)[];
export type RecipeRunFieldPolicy = {
	commitJobs?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	end?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>,
	priority?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>,
	start?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	summaryResultsPages?: FieldPolicy<any> | FieldReadFunction<any>,
	totals?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizationRuns?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunHistoryKeySpecifier = ('end' | 'recipe' | 'recipeRun' | 'runId' | 'start' | RecipeRunHistoryKeySpecifier)[];
export type RecipeRunHistoryFieldPolicy = {
	end?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>,
	start?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunHistoryConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | RecipeRunHistoryConnectionKeySpecifier)[];
export type RecipeRunHistoryConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunHistoryEdgeKeySpecifier = ('cursor' | 'node' | RecipeRunHistoryEdgeKeySpecifier)[];
export type RecipeRunHistoryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunPerformanceKeySpecifier = ('astLoading' | 'dependencyResolution' | 'recipeRun' | RecipeRunPerformanceKeySpecifier)[];
export type RecipeRunPerformanceFieldPolicy = {
	astLoading?: FieldPolicy<any> | FieldReadFunction<any>,
	dependencyResolution?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRun?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunReportDownloadTaskKeySpecifier = ('fileSize' | 'id' | 'state' | 'stateMessage' | 'url' | RecipeRunReportDownloadTaskKeySpecifier)[];
export type RecipeRunReportDownloadTaskFieldPolicy = {
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunSummaryKeySpecifier = ('commit' | 'dataTables' | 'debugMarkers' | 'errorMarkers' | 'errorReason' | 'infoMarkers' | 'lastUpdated' | 'organizationId' | 'performance' | 'queuePosition' | 'repository' | 'runId' | 'state' | 'stateMessage' | 'timeSavings' | 'totalChanged' | 'totalResults' | 'totalSearched' | 'validationState' | 'validationUrl' | 'warningMarkers' | 'worker' | RecipeRunSummaryKeySpecifier)[];
export type RecipeRunSummaryFieldPolicy = {
	commit?: FieldPolicy<any> | FieldReadFunction<any>,
	dataTables?: FieldPolicy<any> | FieldReadFunction<any>,
	debugMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	errorMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	errorReason?: FieldPolicy<any> | FieldReadFunction<any>,
	infoMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>,
	performance?: FieldPolicy<any> | FieldReadFunction<any>,
	queuePosition?: FieldPolicy<any> | FieldReadFunction<any>,
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	timeSavings?: FieldPolicy<any> | FieldReadFunction<any>,
	totalChanged?: FieldPolicy<any> | FieldReadFunction<any>,
	totalResults?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSearched?: FieldPolicy<any> | FieldReadFunction<any>,
	validationState?: FieldPolicy<any> | FieldReadFunction<any>,
	validationUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	warningMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	worker?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunSummaryConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | RecipeRunSummaryConnectionKeySpecifier)[];
export type RecipeRunSummaryConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunSummaryEdgeKeySpecifier = ('cursor' | 'node' | RecipeRunSummaryEdgeKeySpecifier)[];
export type RecipeRunSummaryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecipeRunTotalsKeySpecifier = ('dataTables' | 'totalDebugMarkers' | 'totalErrorMarkers' | 'totalFilesChanged' | 'totalFilesResults' | 'totalFilesSearched' | 'totalInfoMarkers' | 'totalRepositoriesInProgress' | 'totalRepositoriesSuccessful' | 'totalRepositoriesWithErrors' | 'totalRepositoriesWithNoChanges' | 'totalRepositoriesWithResults' | 'totalResults' | 'totalTimeSavings' | 'totalWarningMarkers' | RecipeRunTotalsKeySpecifier)[];
export type RecipeRunTotalsFieldPolicy = {
	dataTables?: FieldPolicy<any> | FieldReadFunction<any>,
	totalDebugMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	totalErrorMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	totalFilesChanged?: FieldPolicy<any> | FieldReadFunction<any>,
	totalFilesResults?: FieldPolicy<any> | FieldReadFunction<any>,
	totalFilesSearched?: FieldPolicy<any> | FieldReadFunction<any>,
	totalInfoMarkers?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositoriesInProgress?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositoriesSuccessful?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositoriesWithErrors?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositoriesWithNoChanges?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositoriesWithResults?: FieldPolicy<any> | FieldReadFunction<any>,
	totalResults?: FieldPolicy<any> | FieldReadFunction<any>,
	totalTimeSavings?: FieldPolicy<any> | FieldReadFunction<any>,
	totalWarningMarkers?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryKeySpecifier = ('branch' | 'id' | 'origin' | 'path' | RepositoryKeySpecifier)[];
export type RepositoryFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	origin?: FieldPolicy<any> | FieldReadFunction<any>,
	path?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryBuildKeySpecifier = ('asts' | 'buildId' | 'buildPluginName' | 'buildPluginVersion' | 'changeset' | 'ingested' | 'weight' | RepositoryBuildKeySpecifier)[];
export type RepositoryBuildFieldPolicy = {
	asts?: FieldPolicy<any> | FieldReadFunction<any>,
	buildId?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginName?: FieldPolicy<any> | FieldReadFunction<any>,
	buildPluginVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>,
	ingested?: FieldPolicy<any> | FieldReadFunction<any>,
	weight?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | RepositoryConnectionKeySpecifier)[];
export type RepositoryConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryEdgeKeySpecifier = ('cursor' | 'node' | RepositoryEdgeKeySpecifier)[];
export type RepositoryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryGroupKeySpecifier = ('description' | 'name' | 'repositories' | RepositoryGroupKeySpecifier)[];
export type RepositoryGroupFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RepositoryProvenanceKeySpecifier = ('branch' | 'changeset' | RepositoryProvenanceKeySpecifier)[];
export type RepositoryProvenanceFieldPolicy = {
	branch?: FieldPolicy<any> | FieldReadFunction<any>,
	changeset?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReviewStatusKeySpecifier = ('approvedBy' | 'reviewDecision' | ReviewStatusKeySpecifier)[];
export type ReviewStatusFieldPolicy = {
	approvedBy?: FieldPolicy<any> | FieldReadFunction<any>,
	reviewDecision?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SecurityCardKeySpecifier = ('measures' | 'name' | 'results' | SecurityCardKeySpecifier)[];
export type SecurityCardFieldPolicy = {
	measures?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	results?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SecurityMeasureKeySpecifier = ('filesChanged' | 'name' | 'occurrences' | 'recipe' | 'repositoriesChanged' | 'riskScore' | 'timeSavings' | 'totalRepositories' | 'updatedAt' | SecurityMeasureKeySpecifier)[];
export type SecurityMeasureFieldPolicy = {
	filesChanged?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	occurrences?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoriesChanged?: FieldPolicy<any> | FieldReadFunction<any>,
	riskScore?: FieldPolicy<any> | FieldReadFunction<any>,
	timeSavings?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ServiceStatisticsKeySpecifier = ('recipeCount' | 'repositoryCount' | 'userRepositoryCount' | ServiceStatisticsKeySpecifier)[];
export type ServiceStatisticsFieldPolicy = {
	recipeCount?: FieldPolicy<any> | FieldReadFunction<any>,
	repositoryCount?: FieldPolicy<any> | FieldReadFunction<any>,
	userRepositoryCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SshConfigurationKeySpecifier = ('port' | SshConfigurationKeySpecifier)[];
export type SshConfigurationFieldPolicy = {
	port?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StatisticsKeySpecifier = ('committers' | 'lineCount' | 'repositories' | StatisticsKeySpecifier)[];
export type StatisticsFieldPolicy = {
	committers?: FieldPolicy<any> | FieldReadFunction<any>,
	lineCount?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ToolConfigurationKeySpecifier = ('agents' | 'alternateUrls' | 'canConnect' | 'id' | 'isSkipSsl' | 'isSkipValidateConnectivity' | 'resourceId' | 'valid' | ToolConfigurationKeySpecifier)[];
export type ToolConfigurationFieldPolicy = {
	agents?: FieldPolicy<any> | FieldReadFunction<any>,
	alternateUrls?: FieldPolicy<any> | FieldReadFunction<any>,
	canConnect?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipSsl?: FieldPolicy<any> | FieldReadFunction<any>,
	isSkipValidateConnectivity?: FieldPolicy<any> | FieldReadFunction<any>,
	resourceId?: FieldPolicy<any> | FieldReadFunction<any>,
	valid?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ToolConnectivityKeySpecifier = ('agentId' | 'isConnected' | 'message' | 'status' | ToolConnectivityKeySpecifier)[];
export type ToolConnectivityFieldPolicy = {
	agentId?: FieldPolicy<any> | FieldReadFunction<any>,
	isConnected?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpgradesAndMigrationsCardKeySpecifier = ('compliantRepositories' | 'fix' | 'measures' | 'notApplicableRepositories' | 'title' | 'totalRepositories' | 'updatedAt' | UpgradesAndMigrationsCardKeySpecifier)[];
export type UpgradesAndMigrationsCardFieldPolicy = {
	compliantRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	fix?: FieldPolicy<any> | FieldReadFunction<any>,
	measures?: FieldPolicy<any> | FieldReadFunction<any>,
	notApplicableRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRepositories?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('email' | 'firstName' | 'lastLogin' | 'lastName' | 'role' | 'username' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	lastLogin?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | UserConnectionKeySpecifier)[];
export type UserConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UsersEdgeKeySpecifier = ('cursor' | 'node' | UsersEdgeKeySpecifier)[];
export type UsersEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationKeySpecifier = ('dataTable' | 'description' | 'id' | 'images' | 'name' | 'options' | 'recipe' | VisualizationKeySpecifier)[];
export type VisualizationFieldPolicy = {
	dataTable?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	images?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	recipe?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationArtifactKeySpecifier = ('indexUrl' | 'installedVersion' | 'name' | 'requestedVersion' | 'visualizations' | VisualizationArtifactKeySpecifier)[];
export type VisualizationArtifactFieldPolicy = {
	indexUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	installedVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	requestedVersion?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationCardKeySpecifier = ('visualization' | VisualizationCardKeySpecifier)[];
export type VisualizationCardFieldPolicy = {
	visualization?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationCategoryKeySpecifier = ('categories' | 'description' | 'id' | 'name' | 'visualizations' | VisualizationCategoryKeySpecifier)[];
export type VisualizationCategoryFieldPolicy = {
	categories?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	visualizations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationDeploymentResultKeySpecifier = ('artifact' | 'duration' | 'id' | 'start' | 'state' | 'stateMessage' | VisualizationDeploymentResultKeySpecifier)[];
export type VisualizationDeploymentResultFieldPolicy = {
	artifact?: FieldPolicy<any> | FieldReadFunction<any>,
	duration?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	start?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationImageKeySpecifier = ('contents' | 'height' | VisualizationImageKeySpecifier)[];
export type VisualizationImageFieldPolicy = {
	contents?: FieldPolicy<any> | FieldReadFunction<any>,
	height?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunKeySpecifier = ('dataTable' | 'email' | 'id' | 'lastUpdatedDate' | 'notebook' | 'organization' | 'organizationId' | 'output' | 'priority' | 'recipeRun' | 'repositories' | 'state' | 'stateMessage' | 'visualization' | VisualizationRunKeySpecifier)[];
export type VisualizationRunFieldPolicy = {
	dataTable?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lastUpdatedDate?: FieldPolicy<any> | FieldReadFunction<any>,
	notebook?: FieldPolicy<any> | FieldReadFunction<any>,
	organization?: FieldPolicy<any> | FieldReadFunction<any>,
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>,
	output?: FieldPolicy<any> | FieldReadFunction<any>,
	priority?: FieldPolicy<any> | FieldReadFunction<any>,
	recipeRun?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	visualization?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunConnectionKeySpecifier = ('count' | 'edges' | 'pageInfo' | VisualizationRunConnectionKeySpecifier)[];
export type VisualizationRunConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunEdgeKeySpecifier = ('cursor' | 'node' | VisualizationRunEdgeKeySpecifier)[];
export type VisualizationRunEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunRepositorySummaryKeySpecifier = ('repository' | 'state' | 'stateMessage' | VisualizationRunRepositorySummaryKeySpecifier)[];
export type VisualizationRunRepositorySummaryFieldPolicy = {
	repository?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	stateMessage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunRepositorySummaryConnectionKeySpecifier = ('count' | 'edges' | 'missingCount' | 'pageInfo' | VisualizationRunRepositorySummaryConnectionKeySpecifier)[];
export type VisualizationRunRepositorySummaryConnectionFieldPolicy = {
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	missingCount?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VisualizationRunRepositorySummaryEdgeKeySpecifier = ('cursor' | 'node' | VisualizationRunRepositorySummaryEdgeKeySpecifier)[];
export type VisualizationRunRepositorySummaryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type WorkerKeySpecifier = ('connectedSince' | 'name' | 'repositories' | WorkerKeySpecifier)[];
export type WorkerFieldPolicy = {
	connectedSince?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	repositories?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AccessToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccessTokenKeySpecifier | (() => undefined | AccessTokenKeySpecifier),
		fields?: AccessTokenFieldPolicy,
	},
	Agent?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AgentKeySpecifier | (() => undefined | AgentKeySpecifier),
		fields?: AgentFieldPolicy,
	},
	AgentConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AgentConnectionKeySpecifier | (() => undefined | AgentConnectionKeySpecifier),
		fields?: AgentConnectionFieldPolicy,
	},
	AgentEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AgentEdgeKeySpecifier | (() => undefined | AgentEdgeKeySpecifier),
		fields?: AgentEdgeFieldPolicy,
	},
	ApproveAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ApproveActionKeySpecifier | (() => undefined | ApproveActionKeySpecifier),
		fields?: ApproveActionFieldPolicy,
	},
	ArtifactDiagnostics?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArtifactDiagnosticsKeySpecifier | (() => undefined | ArtifactDiagnosticsKeySpecifier),
		fields?: ArtifactDiagnosticsFieldPolicy,
	},
	ArtifactStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArtifactStatsKeySpecifier | (() => undefined | ArtifactStatsKeySpecifier),
		fields?: ArtifactStatsFieldPolicy,
	},
	ArtifactoryConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArtifactoryConfigurationKeySpecifier | (() => undefined | ArtifactoryConfigurationKeySpecifier),
		fields?: ArtifactoryConfigurationFieldPolicy,
	},
	Ast?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstKeySpecifier | (() => undefined | AstKeySpecifier),
		fields?: AstFieldPolicy,
	},
	AstArtifact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstArtifactKeySpecifier | (() => undefined | AstArtifactKeySpecifier),
		fields?: AstArtifactFieldPolicy,
	},
	AstArtifactRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstArtifactRepositoryKeySpecifier | (() => undefined | AstArtifactRepositoryKeySpecifier),
		fields?: AstArtifactRepositoryFieldPolicy,
	},
	AstArtifactRepositoryDiagnostic?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstArtifactRepositoryDiagnosticKeySpecifier | (() => undefined | AstArtifactRepositoryDiagnosticKeySpecifier),
		fields?: AstArtifactRepositoryDiagnosticFieldPolicy,
	},
	AstConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstConnectionKeySpecifier | (() => undefined | AstConnectionKeySpecifier),
		fields?: AstConnectionFieldPolicy,
	},
	AstEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstEdgeKeySpecifier | (() => undefined | AstEdgeKeySpecifier),
		fields?: AstEdgeFieldPolicy,
	},
	AstRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AstRepositoryKeySpecifier | (() => undefined | AstRepositoryKeySpecifier),
		fields?: AstRepositoryFieldPolicy,
	},
	AzureDevOpsConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AzureDevOpsConfigurationKeySpecifier | (() => undefined | AzureDevOpsConfigurationKeySpecifier),
		fields?: AzureDevOpsConfigurationFieldPolicy,
	},
	AzureDevOpsRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AzureDevOpsRepositoryKeySpecifier | (() => undefined | AzureDevOpsRepositoryKeySpecifier),
		fields?: AzureDevOpsRepositoryFieldPolicy,
	},
	BaseRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BaseRepositoryKeySpecifier | (() => undefined | BaseRepositoryKeySpecifier),
		fields?: BaseRepositoryFieldPolicy,
	},
	BitbucketCloudConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BitbucketCloudConfigurationKeySpecifier | (() => undefined | BitbucketCloudConfigurationKeySpecifier),
		fields?: BitbucketCloudConfigurationFieldPolicy,
	},
	BitbucketCloudRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BitbucketCloudRepositoryKeySpecifier | (() => undefined | BitbucketCloudRepositoryKeySpecifier),
		fields?: BitbucketCloudRepositoryFieldPolicy,
	},
	BitbucketConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BitbucketConfigurationKeySpecifier | (() => undefined | BitbucketConfigurationKeySpecifier),
		fields?: BitbucketConfigurationFieldPolicy,
	},
	BitbucketRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BitbucketRepositoryKeySpecifier | (() => undefined | BitbucketRepositoryKeySpecifier),
		fields?: BitbucketRepositoryFieldPolicy,
	},
	BranchCommitOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BranchCommitOptionsKeySpecifier | (() => undefined | BranchCommitOptionsKeySpecifier),
		fields?: BranchCommitOptionsFieldPolicy,
	},
	CloseAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CloseActionKeySpecifier | (() => undefined | CloseActionKeySpecifier),
		fields?: CloseActionFieldPolicy,
	},
	Column?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ColumnKeySpecifier | (() => undefined | ColumnKeySpecifier),
		fields?: ColumnFieldPolicy,
	},
	Commit?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitKeySpecifier | (() => undefined | CommitKeySpecifier),
		fields?: CommitFieldPolicy,
	},
	CommitConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitConnectionKeySpecifier | (() => undefined | CommitConnectionKeySpecifier),
		fields?: CommitConnectionFieldPolicy,
	},
	CommitEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitEdgeKeySpecifier | (() => undefined | CommitEdgeKeySpecifier),
		fields?: CommitEdgeFieldPolicy,
	},
	CommitJob?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitJobKeySpecifier | (() => undefined | CommitJobKeySpecifier),
		fields?: CommitJobFieldPolicy,
	},
	CommitJobConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitJobConnectionKeySpecifier | (() => undefined | CommitJobConnectionKeySpecifier),
		fields?: CommitJobConnectionFieldPolicy,
	},
	CommitJobEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitJobEdgeKeySpecifier | (() => undefined | CommitJobEdgeKeySpecifier),
		fields?: CommitJobEdgeFieldPolicy,
	},
	CommitJobSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitJobSummaryKeySpecifier | (() => undefined | CommitJobSummaryKeySpecifier),
		fields?: CommitJobSummaryFieldPolicy,
	},
	CommitOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitOptionsKeySpecifier | (() => undefined | CommitOptionsKeySpecifier),
		fields?: CommitOptionsFieldPolicy,
	},
	CommitsReportDownloadTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CommitsReportDownloadTaskKeySpecifier | (() => undefined | CommitsReportDownloadTaskKeySpecifier),
		fields?: CommitsReportDownloadTaskFieldPolicy,
	},
	Contributor?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContributorKeySpecifier | (() => undefined | ContributorKeySpecifier),
		fields?: ContributorFieldPolicy,
	},
	CustomRecipe?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CustomRecipeKeySpecifier | (() => undefined | CustomRecipeKeySpecifier),
		fields?: CustomRecipeFieldPolicy,
	},
	Dashboard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DashboardKeySpecifier | (() => undefined | DashboardKeySpecifier),
		fields?: DashboardFieldPolicy,
	},
	DataTable?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTableKeySpecifier | (() => undefined | DataTableKeySpecifier),
		fields?: DataTableFieldPolicy,
	},
	DataTableDevCenterDownloadTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTableDevCenterDownloadTaskKeySpecifier | (() => undefined | DataTableDevCenterDownloadTaskKeySpecifier),
		fields?: DataTableDevCenterDownloadTaskFieldPolicy,
	},
	DataTableDownloadStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTableDownloadStatsKeySpecifier | (() => undefined | DataTableDownloadStatsKeySpecifier),
		fields?: DataTableDownloadStatsFieldPolicy,
	},
	DataTableDownloadTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTableDownloadTaskKeySpecifier | (() => undefined | DataTableDownloadTaskKeySpecifier),
		fields?: DataTableDownloadTaskFieldPolicy,
	},
	Event?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EventKeySpecifier | (() => undefined | EventKeySpecifier),
		fields?: EventFieldPolicy,
	},
	EventConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EventConnectionKeySpecifier | (() => undefined | EventConnectionKeySpecifier),
		fields?: EventConnectionFieldPolicy,
	},
	EventEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EventEdgeKeySpecifier | (() => undefined | EventEdgeKeySpecifier),
		fields?: EventEdgeFieldPolicy,
	},
	ForkCommitOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ForkCommitOptionsKeySpecifier | (() => undefined | ForkCommitOptionsKeySpecifier),
		fields?: ForkCommitOptionsFieldPolicy,
	},
	ForkPullRequestOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ForkPullRequestOptionsKeySpecifier | (() => undefined | ForkPullRequestOptionsKeySpecifier),
		fields?: ForkPullRequestOptionsFieldPolicy,
	},
	GenericHttpToolConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GenericHttpToolConfigurationKeySpecifier | (() => undefined | GenericHttpToolConfigurationKeySpecifier),
		fields?: GenericHttpToolConfigurationFieldPolicy,
	},
	GitHubRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GitHubRepositoryKeySpecifier | (() => undefined | GitHubRepositoryKeySpecifier),
		fields?: GitHubRepositoryFieldPolicy,
	},
	GitLabConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GitLabConfigurationKeySpecifier | (() => undefined | GitLabConfigurationKeySpecifier),
		fields?: GitLabConfigurationFieldPolicy,
	},
	GitLabRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GitLabRepositoryKeySpecifier | (() => undefined | GitLabRepositoryKeySpecifier),
		fields?: GitLabRepositoryFieldPolicy,
	},
	GithubConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GithubConfigurationKeySpecifier | (() => undefined | GithubConfigurationKeySpecifier),
		fields?: GithubConfigurationFieldPolicy,
	},
	GroupArtifactVersion?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupArtifactVersionKeySpecifier | (() => undefined | GroupArtifactVersionKeySpecifier),
		fields?: GroupArtifactVersionFieldPolicy,
	},
	JenkinsConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | JenkinsConfigurationKeySpecifier | (() => undefined | JenkinsConfigurationKeySpecifier),
		fields?: JenkinsConfigurationFieldPolicy,
	},
	LineCountPerRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LineCountPerRepositoryKeySpecifier | (() => undefined | LineCountPerRepositoryKeySpecifier),
		fields?: LineCountPerRepositoryFieldPolicy,
	},
	Maintainer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MaintainerKeySpecifier | (() => undefined | MaintainerKeySpecifier),
		fields?: MaintainerFieldPolicy,
	},
	MavenConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MavenConfigurationKeySpecifier | (() => undefined | MavenConfigurationKeySpecifier),
		fields?: MavenConfigurationFieldPolicy,
	},
	MavenIndexProperty?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MavenIndexPropertyKeySpecifier | (() => undefined | MavenIndexPropertyKeySpecifier),
		fields?: MavenIndexPropertyFieldPolicy,
	},
	MavenResponseStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MavenResponseStatusKeySpecifier | (() => undefined | MavenResponseStatusKeySpecifier),
		fields?: MavenResponseStatusFieldPolicy,
	},
	Measure?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MeasureKeySpecifier | (() => undefined | MeasureKeySpecifier),
		fields?: MeasureFieldPolicy,
	},
	MergeAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MergeActionKeySpecifier | (() => undefined | MergeActionKeySpecifier),
		fields?: MergeActionFieldPolicy,
	},
	MergeOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MergeOptionsKeySpecifier | (() => undefined | MergeOptionsKeySpecifier),
		fields?: MergeOptionsFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NugetConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NugetConfigurationKeySpecifier | (() => undefined | NugetConfigurationKeySpecifier),
		fields?: NugetConfigurationFieldPolicy,
	},
	OAuthConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OAuthConfigurationKeySpecifier | (() => undefined | OAuthConfigurationKeySpecifier),
		fields?: OAuthConfigurationFieldPolicy,
	},
	Option?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OptionKeySpecifier | (() => undefined | OptionKeySpecifier),
		fields?: OptionFieldPolicy,
	},
	Organization?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationKeySpecifier | (() => undefined | OrganizationKeySpecifier),
		fields?: OrganizationFieldPolicy,
	},
	OrganizationConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationConfigurationKeySpecifier | (() => undefined | OrganizationConfigurationKeySpecifier),
		fields?: OrganizationConfigurationFieldPolicy,
	},
	OrganizationConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationConnectionKeySpecifier | (() => undefined | OrganizationConnectionKeySpecifier),
		fields?: OrganizationConnectionFieldPolicy,
	},
	OrganizationEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationEdgeKeySpecifier | (() => undefined | OrganizationEdgeKeySpecifier),
		fields?: OrganizationEdgeFieldPolicy,
	},
	OrganizationStatisticCard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationStatisticCardKeySpecifier | (() => undefined | OrganizationStatisticCardKeySpecifier),
		fields?: OrganizationStatisticCardFieldPolicy,
	},
	OrganizationSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrganizationSummaryKeySpecifier | (() => undefined | OrganizationSummaryKeySpecifier),
		fields?: OrganizationSummaryFieldPolicy,
	},
	OrphanedRepository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrphanedRepositoryKeySpecifier | (() => undefined | OrphanedRepositoryKeySpecifier),
		fields?: OrphanedRepositoryFieldPolicy,
	},
	Page?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageKeySpecifier | (() => undefined | PageKeySpecifier),
		fields?: PageFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PullRequestAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionKeySpecifier | (() => undefined | PullRequestActionKeySpecifier),
		fields?: PullRequestActionFieldPolicy,
	},
	PullRequestActionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionConnectionKeySpecifier | (() => undefined | PullRequestActionConnectionKeySpecifier),
		fields?: PullRequestActionConnectionFieldPolicy,
	},
	PullRequestActionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionEdgeKeySpecifier | (() => undefined | PullRequestActionEdgeKeySpecifier),
		fields?: PullRequestActionEdgeFieldPolicy,
	},
	PullRequestActionJob?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionJobKeySpecifier | (() => undefined | PullRequestActionJobKeySpecifier),
		fields?: PullRequestActionJobFieldPolicy,
	},
	PullRequestActionJobConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionJobConnectionKeySpecifier | (() => undefined | PullRequestActionJobConnectionKeySpecifier),
		fields?: PullRequestActionJobConnectionFieldPolicy,
	},
	PullRequestActionJobEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionJobEdgeKeySpecifier | (() => undefined | PullRequestActionJobEdgeKeySpecifier),
		fields?: PullRequestActionJobEdgeFieldPolicy,
	},
	PullRequestActionJobSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestActionJobSummaryKeySpecifier | (() => undefined | PullRequestActionJobSummaryKeySpecifier),
		fields?: PullRequestActionJobSummaryFieldPolicy,
	},
	PullRequestOptions?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestOptionsKeySpecifier | (() => undefined | PullRequestOptionsKeySpecifier),
		fields?: PullRequestOptionsFieldPolicy,
	},
	PullRequestStatistics?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestStatisticsKeySpecifier | (() => undefined | PullRequestStatisticsKeySpecifier),
		fields?: PullRequestStatisticsFieldPolicy,
	},
	PullRequestStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PullRequestStatusKeySpecifier | (() => undefined | PullRequestStatusKeySpecifier),
		fields?: PullRequestStatusFieldPolicy,
	},
	PypiConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PypiConfigurationKeySpecifier | (() => undefined | PypiConfigurationKeySpecifier),
		fields?: PypiConfigurationFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Recipe?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeKeySpecifier | (() => undefined | RecipeKeySpecifier),
		fields?: RecipeFieldPolicy,
	},
	RecipeArtifact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeArtifactKeySpecifier | (() => undefined | RecipeArtifactKeySpecifier),
		fields?: RecipeArtifactFieldPolicy,
	},
	RecipeCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeCategoryKeySpecifier | (() => undefined | RecipeCategoryKeySpecifier),
		fields?: RecipeCategoryFieldPolicy,
	},
	RecipeCategoryBreadcrumb?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeCategoryBreadcrumbKeySpecifier | (() => undefined | RecipeCategoryBreadcrumbKeySpecifier),
		fields?: RecipeCategoryBreadcrumbFieldPolicy,
	},
	RecipeConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeConnectionKeySpecifier | (() => undefined | RecipeConnectionKeySpecifier),
		fields?: RecipeConnectionFieldPolicy,
	},
	RecipeDeploymentResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeDeploymentResultKeySpecifier | (() => undefined | RecipeDeploymentResultKeySpecifier),
		fields?: RecipeDeploymentResultFieldPolicy,
	},
	RecipeEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeEdgeKeySpecifier | (() => undefined | RecipeEdgeKeySpecifier),
		fields?: RecipeEdgeFieldPolicy,
	},
	RecipeRun?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunKeySpecifier | (() => undefined | RecipeRunKeySpecifier),
		fields?: RecipeRunFieldPolicy,
	},
	RecipeRunHistory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunHistoryKeySpecifier | (() => undefined | RecipeRunHistoryKeySpecifier),
		fields?: RecipeRunHistoryFieldPolicy,
	},
	RecipeRunHistoryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunHistoryConnectionKeySpecifier | (() => undefined | RecipeRunHistoryConnectionKeySpecifier),
		fields?: RecipeRunHistoryConnectionFieldPolicy,
	},
	RecipeRunHistoryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunHistoryEdgeKeySpecifier | (() => undefined | RecipeRunHistoryEdgeKeySpecifier),
		fields?: RecipeRunHistoryEdgeFieldPolicy,
	},
	RecipeRunPerformance?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunPerformanceKeySpecifier | (() => undefined | RecipeRunPerformanceKeySpecifier),
		fields?: RecipeRunPerformanceFieldPolicy,
	},
	RecipeRunReportDownloadTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunReportDownloadTaskKeySpecifier | (() => undefined | RecipeRunReportDownloadTaskKeySpecifier),
		fields?: RecipeRunReportDownloadTaskFieldPolicy,
	},
	RecipeRunSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunSummaryKeySpecifier | (() => undefined | RecipeRunSummaryKeySpecifier),
		fields?: RecipeRunSummaryFieldPolicy,
	},
	RecipeRunSummaryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunSummaryConnectionKeySpecifier | (() => undefined | RecipeRunSummaryConnectionKeySpecifier),
		fields?: RecipeRunSummaryConnectionFieldPolicy,
	},
	RecipeRunSummaryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunSummaryEdgeKeySpecifier | (() => undefined | RecipeRunSummaryEdgeKeySpecifier),
		fields?: RecipeRunSummaryEdgeFieldPolicy,
	},
	RecipeRunTotals?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecipeRunTotalsKeySpecifier | (() => undefined | RecipeRunTotalsKeySpecifier),
		fields?: RecipeRunTotalsFieldPolicy,
	},
	Repository?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryKeySpecifier | (() => undefined | RepositoryKeySpecifier),
		fields?: RepositoryFieldPolicy,
	},
	RepositoryBuild?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryBuildKeySpecifier | (() => undefined | RepositoryBuildKeySpecifier),
		fields?: RepositoryBuildFieldPolicy,
	},
	RepositoryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryConnectionKeySpecifier | (() => undefined | RepositoryConnectionKeySpecifier),
		fields?: RepositoryConnectionFieldPolicy,
	},
	RepositoryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryEdgeKeySpecifier | (() => undefined | RepositoryEdgeKeySpecifier),
		fields?: RepositoryEdgeFieldPolicy,
	},
	RepositoryGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryGroupKeySpecifier | (() => undefined | RepositoryGroupKeySpecifier),
		fields?: RepositoryGroupFieldPolicy,
	},
	RepositoryProvenance?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RepositoryProvenanceKeySpecifier | (() => undefined | RepositoryProvenanceKeySpecifier),
		fields?: RepositoryProvenanceFieldPolicy,
	},
	ReviewStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReviewStatusKeySpecifier | (() => undefined | ReviewStatusKeySpecifier),
		fields?: ReviewStatusFieldPolicy,
	},
	SecurityCard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SecurityCardKeySpecifier | (() => undefined | SecurityCardKeySpecifier),
		fields?: SecurityCardFieldPolicy,
	},
	SecurityMeasure?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SecurityMeasureKeySpecifier | (() => undefined | SecurityMeasureKeySpecifier),
		fields?: SecurityMeasureFieldPolicy,
	},
	ServiceStatistics?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ServiceStatisticsKeySpecifier | (() => undefined | ServiceStatisticsKeySpecifier),
		fields?: ServiceStatisticsFieldPolicy,
	},
	SshConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SshConfigurationKeySpecifier | (() => undefined | SshConfigurationKeySpecifier),
		fields?: SshConfigurationFieldPolicy,
	},
	Statistics?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StatisticsKeySpecifier | (() => undefined | StatisticsKeySpecifier),
		fields?: StatisticsFieldPolicy,
	},
	ToolConfiguration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ToolConfigurationKeySpecifier | (() => undefined | ToolConfigurationKeySpecifier),
		fields?: ToolConfigurationFieldPolicy,
	},
	ToolConnectivity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ToolConnectivityKeySpecifier | (() => undefined | ToolConnectivityKeySpecifier),
		fields?: ToolConnectivityFieldPolicy,
	},
	UpgradesAndMigrationsCard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpgradesAndMigrationsCardKeySpecifier | (() => undefined | UpgradesAndMigrationsCardKeySpecifier),
		fields?: UpgradesAndMigrationsCardFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserConnectionKeySpecifier | (() => undefined | UserConnectionKeySpecifier),
		fields?: UserConnectionFieldPolicy,
	},
	UsersEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UsersEdgeKeySpecifier | (() => undefined | UsersEdgeKeySpecifier),
		fields?: UsersEdgeFieldPolicy,
	},
	Visualization?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationKeySpecifier | (() => undefined | VisualizationKeySpecifier),
		fields?: VisualizationFieldPolicy,
	},
	VisualizationArtifact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationArtifactKeySpecifier | (() => undefined | VisualizationArtifactKeySpecifier),
		fields?: VisualizationArtifactFieldPolicy,
	},
	VisualizationCard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationCardKeySpecifier | (() => undefined | VisualizationCardKeySpecifier),
		fields?: VisualizationCardFieldPolicy,
	},
	VisualizationCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationCategoryKeySpecifier | (() => undefined | VisualizationCategoryKeySpecifier),
		fields?: VisualizationCategoryFieldPolicy,
	},
	VisualizationDeploymentResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationDeploymentResultKeySpecifier | (() => undefined | VisualizationDeploymentResultKeySpecifier),
		fields?: VisualizationDeploymentResultFieldPolicy,
	},
	VisualizationImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationImageKeySpecifier | (() => undefined | VisualizationImageKeySpecifier),
		fields?: VisualizationImageFieldPolicy,
	},
	VisualizationRun?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunKeySpecifier | (() => undefined | VisualizationRunKeySpecifier),
		fields?: VisualizationRunFieldPolicy,
	},
	VisualizationRunConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunConnectionKeySpecifier | (() => undefined | VisualizationRunConnectionKeySpecifier),
		fields?: VisualizationRunConnectionFieldPolicy,
	},
	VisualizationRunEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunEdgeKeySpecifier | (() => undefined | VisualizationRunEdgeKeySpecifier),
		fields?: VisualizationRunEdgeFieldPolicy,
	},
	VisualizationRunRepositorySummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunRepositorySummaryKeySpecifier | (() => undefined | VisualizationRunRepositorySummaryKeySpecifier),
		fields?: VisualizationRunRepositorySummaryFieldPolicy,
	},
	VisualizationRunRepositorySummaryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunRepositorySummaryConnectionKeySpecifier | (() => undefined | VisualizationRunRepositorySummaryConnectionKeySpecifier),
		fields?: VisualizationRunRepositorySummaryConnectionFieldPolicy,
	},
	VisualizationRunRepositorySummaryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VisualizationRunRepositorySummaryEdgeKeySpecifier | (() => undefined | VisualizationRunRepositorySummaryEdgeKeySpecifier),
		fields?: VisualizationRunRepositorySummaryEdgeFieldPolicy,
	},
	Worker?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | WorkerKeySpecifier | (() => undefined | WorkerKeySpecifier),
		fields?: WorkerFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const GraphQLOperations = {
  Query: {
    auditLogs: 'auditLogs' as const,
    workers: 'workers' as const,
    quarantinedRepositories: 'quarantinedRepositories' as const,
    agentVersionCheck: 'agentVersionCheck' as const,
    agents: 'agents' as const,
    tools: 'tools' as const,
    httpTool: 'httpTool' as const,
    users: 'users' as const,
    hasScmToken: 'hasScmToken' as const,
    scmConfiguration: 'scmConfiguration' as const,
    scmConfigurations: 'scmConfigurations' as const,
    accessTokenEmails: 'accessTokenEmails' as const,
    commitJobPullRequestActions: 'commitJobPullRequestActions' as const,
    commitJob: 'commitJob' as const,
    commitJobHistory: 'commitJobHistory' as const,
    allCommitJobHistory: 'allCommitJobHistory' as const,
    organizationExists: 'organizationExists' as const,
    selectAllCommitJobsWithResults: 'selectAllCommitJobsWithResults' as const,
    recipeDataTablesDefined: 'recipeDataTablesDefined' as const,
    recipeRunDataTablesProduced: 'recipeRunDataTablesProduced' as const,
    dataTableDownload: 'dataTableDownload' as const,
    dataTableOrigins: 'dataTableOrigins' as const,
    devCenterDataTableDownload: 'devCenterDataTableDownload' as const,
    rootCategories: 'rootCategories' as const,
    subCategory: 'subCategory' as const,
    autoCompleteResults: 'autoCompleteResults' as const,
    devCenter: 'devCenter' as const,
    availableDashboardRecipeRun: 'availableDashboardRecipeRun' as const,
    organizations: 'organizations' as const,
    organizationSelectorGrid: 'organizationSelectorGrid' as const,
    cloneOrganizationFrom: 'cloneOrganizationFrom' as const,
    defaultOrganization: 'defaultOrganization' as const,
    simpleOrganizationById: 'simpleOrganizationById' as const,
    organizationsById: 'organizationsById' as const,
    repositoryIdsByOrganizationId: 'repositoryIdsByOrganizationId' as const,
    organizationsBreadcrumbs: 'organizationsBreadcrumbs' as const,
    allRepositories: 'allRepositories' as const,
    findRepository: 'findRepository' as const,
    repositoryQuickView: 'repositoryQuickView' as const,
    repoMetricsSummary: 'repoMetricsSummary' as const,
    defaultCommitOptions: 'defaultCommitOptions' as const,
    searchRecipes: 'searchRecipes' as const,
    recipeCard: 'recipeCard' as const,
    recipeDetail: 'recipeDetail' as const,
    customRecipeForRecipeRun: 'customRecipeForRecipeRun' as const,
    yamlFromRecipeRun: 'yamlFromRecipeRun' as const,
    welcomeBanner: 'welcomeBanner' as const,
    canDeployArtifacts: 'canDeployArtifacts' as const,
    currentRecipeJars: 'currentRecipeJars' as const,
    recipeRunReportDownload: 'recipeRunReportDownload' as const,
    commitsDownload: 'commitsDownload' as const,
    recipeRunDetails: 'recipeRunDetails' as const,
    selectAllRepositories: 'selectAllRepositories' as const,
    recipeRunResultsName: 'recipeRunResultsName' as const,
    recipeRunResultsNotification: 'recipeRunResultsNotification' as const,
    recipeRunResults: 'recipeRunResults' as const,
    getRecipeResultChangeSetForRepository: 'getRecipeResultChangeSetForRepository' as const,
    previousRecipeRunHistory: 'previousRecipeRunHistory' as const,
    allRecipeRunHistory: 'allRecipeRunHistory' as const,
    recentActivity: 'recentActivity' as const,
    replayRecipeOnResults: 'replayRecipeOnResults' as const,
    visualizationRecipeProgress: 'visualizationRecipeProgress' as const,
    recipeRunNavigationDataTables: 'recipeRunNavigationDataTables' as const,
    recipeRunNavigationVisualizations: 'recipeRunNavigationVisualizations' as const,
    recipeRunNavigationSummary: 'recipeRunNavigationSummary' as const,
    myTokens: 'myTokens' as const,
    pullRequestStatistics: 'pullRequestStatistics' as const,
    visualizationCategory: 'visualizationCategory' as const,
    visualizationsForRecipeRun: 'visualizationsForRecipeRun' as const,
    visualization: 'visualization' as const,
    visualizationRun: 'visualizationRun' as const,
    visualizationRunDetail: 'visualizationRunDetail' as const,
    availableVisualizationRun: 'availableVisualizationRun' as const,
    visualizationRunHistory: 'visualizationRunHistory' as const,
    userVisualizationRunHistory: 'userVisualizationRunHistory' as const,
    allVisualizationRunHistory: 'allVisualizationRunHistory' as const,
    visualizationDeployments: 'visualizationDeployments' as const
  },
  Mutation: {
    quarantineRepository: 'quarantineRepository' as const,
    unquarantineRepository: 'unquarantineRepository' as const,
    indexAsts: 'indexAsts' as const,
    exchangeGithubAuthorizationCode: 'exchangeGithubAuthorizationCode' as const,
    exchangeGitLabAuthorizationCode: 'exchangeGitLabAuthorizationCode' as const,
    revokeScmAuthorization: 'revokeScmAuthorization' as const,
    revokeAllScmAuthorization: 'revokeAllScmAuthorization' as const,
    getBitbucketRequestToken: 'getBitbucketRequestToken' as const,
    exchangeBitbucketAuthorizationVerifier: 'exchangeBitbucketAuthorizationVerifier' as const,
    exchangeBitbucketCloudAuthorizationCode: 'exchangeBitbucketCloudAuthorizationCode' as const,
    exchangeAzureDevOpsAuthorizationCode: 'exchangeAzureDevOpsAuthorizationCode' as const,
    deleteAllAccessTokens: 'deleteAllAccessTokens' as const,
    commit: 'commit' as const,
    forkAndCommit: 'forkAndCommit' as const,
    pullRequest: 'pullRequest' as const,
    forkAndPullRequest: 'forkAndPullRequest' as const,
    cancelCommitJob: 'cancelCommitJob' as const,
    rerunFailedCommitJob: 'rerunFailedCommitJob' as const,
    approvePullRequests: 'approvePullRequests' as const,
    closePullRequests: 'closePullRequests' as const,
    mergePullRequests: 'mergePullRequests' as const,
    rerunFailedPullRequestActionJob: 'rerunFailedPullRequestActionJob' as const,
    startDataTableDownload: 'startDataTableDownload' as const,
    downloadDevCenterDataTable: 'downloadDevCenterDataTable' as const,
    runRepoMetrics: 'runRepoMetrics' as const,
    removeRepositoryArtifact: 'removeRepositoryArtifact' as const,
    refreshOrganizations: 'refreshOrganizations' as const,
    createUserOrganization: 'createUserOrganization' as const,
    updateUserOrganization: 'updateUserOrganization' as const,
    removeRepositoriesFromUserOrganization: 'removeRepositoriesFromUserOrganization' as const,
    deleteUserOrganization: 'deleteUserOrganization' as const,
    runRecipeFromYaml: 'runRecipeFromYaml' as const,
    runRecipe: 'runRecipe' as const,
    cancelRecipeRun: 'cancelRecipeRun' as const,
    cancelAllRecipeRuns: 'cancelAllRecipeRuns' as const,
    loadRecipes: 'loadRecipes' as const,
    reloadRecipeArtifact: 'reloadRecipeArtifact' as const,
    unloadRecipeArtifact: 'unloadRecipeArtifact' as const,
    downloadRecipeRunReport: 'downloadRecipeRunReport' as const,
    downloadCommits: 'downloadCommits' as const,
    createToken: 'createToken' as const,
    removeToken: 'removeToken' as const,
    runVisualization: 'runVisualization' as const,
    cancelVisualizationRun: 'cancelVisualizationRun' as const,
    deleteVisualizationArtifact: 'deleteVisualizationArtifact' as const,
    loadVisualizationsAsync: 'loadVisualizationsAsync' as const,
    loadDefaultVisualization: 'loadDefaultVisualization' as const
  },
  Fragment: {
    pageInfo: 'pageInfo' as const,
    AuditLogEvent: 'AuditLogEvent' as const,
    CategoryDetails: 'CategoryDetails' as const,
    EnrichedRepository: 'EnrichedRepository' as const,
    RepositoryIdentity: 'RepositoryIdentity' as const,
    Repository: 'Repository' as const,
    RecipeArtifactDetails: 'RecipeArtifactDetails' as const,
    RecipeRunSummaryDetails: 'RecipeRunSummaryDetails' as const,
    RecipeRunSummaryTotals: 'RecipeRunSummaryTotals' as const,
    RecipeDetailsForResultsSummary: 'RecipeDetailsForResultsSummary' as const,
    RecipeDetails: 'RecipeDetails' as const,
    Option: 'Option' as const,
    WorkerDetails: 'WorkerDetails' as const,
    CommitDetails: 'CommitDetails' as const,
    DashboardToolDetails: 'DashboardToolDetails' as const,
    AgentDetails: 'AgentDetails' as const,
    CommitJobOptions: 'CommitJobOptions' as const,
    PullRequestActionJob: 'PullRequestActionJob' as const,
    PullRequestActionTask: 'PullRequestActionTask' as const,
    AssociatedAgent: 'AssociatedAgent' as const,
    ToolConfigurationDetails: 'ToolConfigurationDetails' as const,
    PullRequestActionConnection: 'PullRequestActionConnection' as const,
    DevCenterVisualization: 'DevCenterVisualization' as const,
    DevCenterStatistic: 'DevCenterStatistic' as const,
    DevCenterSecurityMeasure: 'DevCenterSecurityMeasure' as const,
    DevCenterUpgradesAndMigrationsResult: 'DevCenterUpgradesAndMigrationsResult' as const,
    UpgradesAndMigrationsFix: 'UpgradesAndMigrationsFix' as const,
    SimpleOrganization: 'SimpleOrganization' as const,
    Organization: 'Organization' as const,
    VisualizationOptions: 'VisualizationOptions' as const,
    Visualization: 'Visualization' as const,
    VisualizationCategory: 'VisualizationCategory' as const,
    VisualizationRun: 'VisualizationRun' as const
  }
}